import React, { useState, useEffect } from 'react';
import { MdMailOutline, MdOutlinePhoneAndroid } from 'react-icons/md';
import { FaInstagram, FaX } from 'react-icons/fa6';
import { FaFacebookF, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { CiSearch } from 'react-icons/ci';
import { RxHamburgerMenu } from 'react-icons/rx';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';
import Map from '../assets/map.png';
import { gsap } from 'gsap';
import Man1 from '../assets/man1.png';
import Man2 from '../assets/man2.png';
import Man3 from '../assets/man3.png';
import { FaArrowRight } from 'react-icons/fa'; // Example icon


const TopBar = () => {
    const location = useLocation();
    const [activePage, setActivePage] = useState(location.pathname);
    const [isSticky, setIsSticky] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hamBurger, setHamBurger] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentBgColorIndex, setCurrentBgColorIndex] = useState(0);
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [hamBurger2, setHamBurger2] = useState(false);
    // Array of images to cycle through
    const manImages = [Man1, Man2, Man3];
    const bgColors = ["orange", "yellow", "blue"];
    const bannerTitles = ["Growth Accelerator", "Your Search Ends Here", "Grow Online"];
    const bannerTexts = [
        "With our proven strategies and innovative approach, we'll help you unlock your business's full potential and achieve long-term success in a rapidly evolving marketplace",
        "When you choose us, you can rest assured that the best services, tailored to your specific requirements, are right at your fingertips.",
        "Expand your reach, optimize your sales funnel, and watch your onlin sales soar to new heights with our ecommerce service."
    ]

    // Change image every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % manImages.length);
            setCurrentBgColorIndex((prevIndex) => (prevIndex + 1) % bgColors.length);
            setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % bannerTitles.length);
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % bannerTexts.length);
        }, 7000);

        return () => clearInterval(interval);
    }, [manImages.length, bgColors.length, bannerTitles.length, bannerTexts.length]);


    const [servicesExpanded, setServicesExpanded] = useState(false);

    const toggleServices = () => {
        setServicesExpanded(!servicesExpanded);
    };


    const handleNavigation = (path) => {
        setActivePage(path);
        setIsDropdownOpen(false);  // Close dropdown on navigation
    };

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            setIsSticky(currentScroll > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const tl = gsap.timeline();
        tl.fromTo('.top-bar', { opacity: 0, x: 10 }, { opacity: 1, duration: 0.5 })
            .fromTo('.text-animation', { opacity: 0, x: -20 }, { opacity: 1, x: 0, duration: 0.2 }, 'a')
            .fromTo('.button-animation', { opacity: 0, x: -20 }, { opacity: 1, x: 0, duration: 0.2 }, 'a')
            .fromTo('.home-li', { opacity: 0 }, { opacity: 1, x: 0, duration: 0.5 }, 'a');

        // Additional animations
        gsap.fromTo('.contact-info-icons', { opacity: 0, x: -20 }, { opacity: 1, x: 0, duration: 1, delay: 0.5 });
        gsap.fromTo('.hamburger-animation', { opacity: 0, scale: 0.5 }, { opacity: 1, scale: 1, duration: 1, delay: 1 });
    }, []);

    useEffect(() => {
        const t2 = gsap.timeline();
        t2.fromTo('.text-animation', { opacity: 0, x: -20 }, { opacity: 1, x: 0, duration: 0.4 }, 'a')
            .fromTo('.button-animation', { opacity: 0, x: -20 }, { opacity: 1, x: 0, duration: 0.2 }, 'a')
            .fromTo('.man-img', { opacity: 0, x: 20 }, { opacity: 1, x: 0, duration: 0.4 }, 'a')
    }, [manImages[currentImageIndex]]);

    return (
        <div className={`${servicesExpanded?'h-[690px]':'h-[520px]'} mb-20 sm:mb-0 sm:h-[650px] max-w-full  flex flex-col relative overflow-hidden `}>
            <div className={`absolute h-full w-full bg-${bgColors[currentBgColorIndex]}-500 z-[100] opacity-[0.8]`}></div>
            <div className="absolute h-[110%] w-full top-[-20px] opacity-[0.4] right-[-60px] flex justify-end">
                <img src={Map} className="h-full" alt="" />
            </div>

            <div className="hidden lg:flex justify-between px-5 items-center h-10 z-[1000] ">
                <div className="flex text-white items-center text-[16px] contact-info-icons">
                    <MdOutlinePhoneAndroid />
                    <p className="mr-4 ml-1">7909702051, 9201746424</p>
                    <MdMailOutline />
                    <a className="mr-4 text-sm cursor-pointer" href="mailto:ecommercebusinesssolutionhub@gmail.com" >ecommercebusinesssolutionhub@gmail.com</a>
                    <a href="https://www.instagram.com/ebsh_2024"><FaInstagram className="mr-2" /></a>
                    <a href="https://www.facebook.com/people/E-Commerce-Business-Solution-Hub/61562861251078/"><FaFacebookF className="mr-2" /></a>
                    <a href="https://x.com/EBSH04"><FaTwitter className="mr-2" /></a>
                    <a href="https://www.linkedin.com/in/ravi-kol-966254319?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedin /></a>
                </div>
                <div className="text-white">Welcome to EBSH</div>
            </div>

            <div className={`h-20 sm:h-24 mt-1 px-5 z-[5001] flex bg-[#ffffff12] items-center relative justify-between ${isSticky ? 'sticky top-0 bg-white' : ''}`}>
                <div onClick={() => setHamBurger(!hamBurger)} className="lg:hidden hamburger-animation">
                    {hamBurger ? <FaX className="text-white text-2xl" /> : <RxHamburgerMenu className="text-white text-3xl" />}
                </div>
                <Link to="/"><div className="h-16 sm:h-20 w-16 sm:w-20 rounded-lg overflow-hidden top-bar ml-[-20px] sm:ml-0">
                    <img src={Logo} className="h-full w-full " alt="" />
                </div></Link>
                <div className="hidden lg:flex  h-full home-li">
                    <ul className="flex transition-all duration-300 uppercase font-bold text-white text-sm h-full justify-center items-center">
                        <li className={`px-5 h-full flex items-center ${activePage === '/' && 'border-b-4 rounded border-white'}  transition-all duration-300`}>
                            <Link to="/" onClick={() => handleNavigation('/')}>Home</Link>
                        </li>
                        <li
                            className={`px-5 h-full flex items-center z-[2000] relative ${activePage === '/services' && 'border-b-4  border-white'} `}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="/services" onClick={() => handleNavigation('/services')}>Our services</Link>

{isDropdownOpen && (
    <div className="absolute top-full left-0 w-56 bg-white shadow-lg py-3 transition-all duration-300 z-[5010]">        <ul className="text-gray-800 text-[13px] font-normal capitalize">
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/ecom-management" onClick={() => handleNavigation('/service1')}>
                        Ecommerce Management
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/website-dev" onClick={() => handleNavigation('/service2')}>
                        Website Development
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/taxation" onClick={() => handleNavigation('/service3')}>
                        Taxation and accounting
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/graphic-design" onClick={() => handleNavigation('/service3')}>
                        Graphic Design
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/advertising" onClick={() => handleNavigation('/service3')}>
                        Advertising
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/digital-marketing" onClick={() => handleNavigation('/digital-marketing')}>
                        Digital Marketing
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/seo" onClick={() => handleNavigation('/service3')}>
                        Search Engine Optimization
                    </Link>
                </span>
            </li>
            <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                    <Link to="/social-media-marketing" onClick={() => handleNavigation('/service3')}>
                        Social Media Marketing
                    </Link>
                </span>
            </li>
        </ul>
    </div>
)}

                        </li>
                        <li className={`px-5 h-full flex items-center ${activePage === '/about' && 'border-b-4 rounded border-white'} `}>
                            <Link to="/about" onClick={() => handleNavigation('/about')}>About us</Link>
                        </li>
                        <li className={`px-5 h-full flex items-center ${activePage === '/contact' && 'border-b-4 rounded border-white'}`}>
                            <Link to="/contact" onClick={() => handleNavigation('/contact')}>Contact us</Link>
                        </li>
                        {/* <li className={`px-5 h-full flex items-center ${activePage === '/testimonials' && 'border-b-4 rounded border-white'}`}>
                            <Link to="/testimonials" onClick={() => handleNavigation('/testimonials')}>Testimonials</Link>
                        </li> */}
                    </ul>
                </div>
                <div className="hidden lg:flex relative z-[6000]" onClick={() => setHamBurger2(!hamBurger2)}>
                    {/* <CiSearch className="text-[#1a1a1d] font-bold" size={26} /> */}
                    {/* {hamBurger2 ? <FaX className="text-white cursor-pointer font-bold ml-3" size={20} /> : <RxHamburgerMenu className="text-[#1a1a1d] cursor-pointer font-bold ml-3" size={26} />} */}
                </div>



                <div className="lg:hidden"></div>
            </div>
            {/* <div className={`absolute bg-[#27272c] transition-all duration-500  top-[0] h-screen w-[350px] ${hamBurger2 ? 'right-0' : 'right-[-100%]'} flex flex-col p-10 pt-20 text-white text-sm z-[5002]`}>
                <p className="font-light  leading-[28px]">Whether you have a specific request, need more information about our services, or simply want to provide feedback, we encourage you to reach out to us. Our dedicated customer support team is committed to providing prompt and helpful assistance to ensure your satisfaction.</p>
                <p className=" mt-6 text-[15px]">Contact us now +91-72248 13564</p>
                <p className=" mt-2 text-[13px]">ecommercebusinesssolutionhub@gmail.com</p>
                <div className="flex mt-7">
                    <FaInstagram className="mr-3 " size={19} />
                    <FaFacebookF className="mr-3" size={19} />
                    <FaTwitter className="" size={19} />
                </div>
            </div> */}

            <div className={`w-full bg-white z-[5020] flex flex-col px-10 py-4 absolute ${hamBurger ? 'top-[80px] opacity-100 block' : 'opacity-0 hidden top-0'} transition-all duration-300`}>
                <Link to="/"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Home</p></Link>
                <div>
                    <div className="flex justify-between items-center border-b py-3 cursor-pointer" onClick={toggleServices}>
                        <p className="mb-2 text-xs font-light hover:text-blue-500">Our Services</p>
                        <span className="text-xs">{servicesExpanded ? '-' : '+'}</span>
                    </div>
                    {servicesExpanded && (
                        <div className="pl-4">
                            <Link to="/ecom-management"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Ecommerce Management</p></Link>
                            <Link to="/website-dev"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Website Development</p></Link>
                            <Link to="/taxation"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Taxation and accounting</p></Link>
                            <Link to="/graphic-design"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Graphic Design</p></Link>
                            <Link to="/advertising"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Advertising</p></Link>
                            <Link to="/digital-marketing"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Digital Marketing</p></Link>
                            <Link to="/seo"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Search Engine optimization</p></Link>
                            <Link to="/social-media-marketing"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Social Media Marketing</p></Link>
                        </div>
                    )}
                </div>
                <Link to="/about"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">About Us</p></Link>
                <Link to="/contact"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Contact Us</p></Link>
                {/* <Link to="/testimonials"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Testimonials</p></Link> */}
            </div>

            <div className="z-[1000] text-white pt-16 items-start flex flex-col w-full relative">
                <div className="h-full flex flex-col items-start pl-3 sm:pl-16 text-animation">
                    <p className="text-[25px] sm:text-[75px] font-bold">{bannerTitles[currentTitleIndex]}</p>
                    <p className="w-[300px] sm:w-[570px] text-sm sm:text-[18px] mt-4 sm:mt-0 leading-relaxed">
                        {bannerTexts[currentTextIndex]}
                    </p>
                    <Link to={"/contact"}><button className="bg-blue-500 py-2 px-4 font-semibold mt-8 sm:mt-3 button-animation cursor-pointer z-[6000]">Contact Us</button></Link>
                </div>

                <div className={`absolute  right-[-100px] sm:right-20  top-[110px] sm:top-[-80px] man-img ${manImages[currentImageIndex] === 2 ? 'p-5' : 'p-32'} sm:p-5 `}>
                    <img src={manImages[currentImageIndex]} alt="" />
                </div>
            </div>
        </div>
    );
};

export default TopBar;
