import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaCheck } from 'react-icons/fa';
import './Popup.css'

const ServicesCard = ({ image, title, price, months, serviceOne, serviceTwo, serviceThree, serviceFour, serviceFive }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);

  const handleGetQuoteClick = () => {
    setIsPopupOpen(true);
  };
//  const successToast=(msg)=>{
//     toast.success(msg, {position: "top-center" });
//   }
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setName('');
    setPhone('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { name, phone, title, months, price };

    try {
      const response = await fetch('https://backend.ecommercebusinesssolutionhub.in/api/v1/get-a-quote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // toast.success('Form submitted successfully!', { position: "top-center" });
        // successToast('Form submitted successfully!');
        alert('Form submitted successfully!');
        console.log('Form submitted successfully:', await response.json());
      } else {
        // toast.error('Form submission error!');
        alert('Form submission error!');
        console.error('Form submission error:', await response.json());
      }
    } catch (error) {
      // toast.error('Form submission error!');
      console.error('Form submission error:', error);
    }

    handleClosePopup(); // Close the popup after submission
  };

  return (
    <div className="flex w-full mx-4 sm:mx-0 sm:w-[300px] flex-col items-center justify-center overflow-hidden bg-blue-500 rounded-md">
      <div className="w-full h-36 overflow-hidden cursor-pointer relative top-0 hover:top-[-15px] transition-all duration-200 flex justify-center items-center">
        <div className="w-20 h-20 rounded-full overflow-hidden bg-white">
          <img className="h-full w-full   rounded-full" src={image} alt="" />
        </div>
      </div>
      <p className=" text-[15px] uppercase text-white bg-blue-400 w-full flex justify-center items-center h-20">{title}</p>
      <p className="flex text-white justify-center items-end font-semibold text-2xl mt-2 ">₹{price}<p className="font-light relative top-[-5px] text-xs">/Month + GST</p></p>
      <p className="mt-10 text-xs text-white flex "><FaCheck className="mr-2" size={15} />{serviceOne}</p>
      <p className="mt-5 text-xs text-white flex "><FaCheck className="mr-2" size={15} />{serviceTwo}</p>
      {serviceThree && <p className="mt-5 text-xs text-white flex "><FaCheck className="mr-2" size={15} />{serviceThree}</p>}
      {serviceFour && <p className="mt-5 text-xs text-white flex "><FaCheck className="mr-2" size={15} />{serviceFour}</p>}
      {serviceFive && <p className="mt-5 text-xs text-white flex "><FaCheck className="mr-2" size={15} />{serviceFive}</p>}
      <div className="mt-8 w-full flex justify-center items-center h-28">
        <button className="w-40 shadow-md h-12 rounded bg-[#1a1a1d] text-white" onClick={handleGetQuoteClick}>
          Choose Plan
        </button>
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1000]  ">
          <div className=" relative p-8  rounded shadow-lg w-full mx-3 sm:w-[450px] h-[400px] flex flex-col justify-center custombg">

            <h2 className="text-xl mb-10 z-[1000] w-full flex flex-col justify-center">Get a Quote for <p className="font-semibold text-[#1a1a1d]">&nbsp;{title}</p></h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 mx-3">
                <label className="block mb-2 text-xs uppercase">Name</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4 mx-3">
                <label className="block mb-2 text-xs  uppercase">Phone</label>
                <input
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded active:border-none active:outline-none"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="flex pt-2 justify-end mx-3">
                {/* <button type="button" className="mr-4" onClick={handleClosePopup}>
                  Cancel
                </button> */}
                <button type="submit" className="bg-blue-600 text-white w-full p-2 rounded">
                  Submit
                </button>
              </div>
            </form>
            {/* <div className="absolute w-full top-0 left-0 rounded h-full overflow-hidden z-[0] opacity-[0.3]">
              <img src={Gradient} className="w-full h-full" alt="" />
            </div> */}
            <div onClick={handleClosePopup} className="absolute top-3 cursor-pointer scale-x-125 right-6 text-lg">
              X
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ServicesCard;
