import React, { useState } from 'react';
import Topbar2 from '../../components/TopBar2';
import FaqItem from '../../components/FaqItem';
import Cloud from '../../assets/cloud.png';
import Footer from '../../components/Footer';
import ServicesCard from '../../components/ServicesCard';
import LogoDesign from '../../assets/logodesign.png';
import ThreeDmodel from '../../assets/3dmodel.png';
import VideoCreation from '../../assets/videocreation.png';
import Infographics from '../../assets/infographics.png';
import OurServices from '../../assets/ourservices.jpg';

const Graphics = () => {
    const [activePricing, setActivePricing] = useState('oneMonth');

    const handlePricingChange = (pricing) => {
        setActivePricing(pricing);
    };

    return (
        <>
            <Topbar2 />
            <div className="w-full h-[300px] md:h-[500px]">
                <img className="h-full w-full object-cover" src={OurServices} alt="" />
            </div>
            <section className="flex flex-col">
            <div className="flex flex-col items-center justify-end h-[150px] md:h-[200px]">
                    <p className="mt-2 text-3xl md:text-5xl font-bold text-[#1a1a1d]">Our Pricing</p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                </div>

                {/* <div className="w-full flex h-20 px-8 sm:px-20  mt-20">
                    <button
                        className={`w-[50%] flex justify-center items-center h-12 ${activePricing === 'oneMonth' ? 'bg-blue-500 text-white' : 'bg-blue-300'} rounded-tl rounded-bl`}
                        onClick={() => handlePricingChange('oneMonth')}
                    >
                        One Month
                    </button>
                    <button
                        className={`w-[50%] flex justify-center items-center h-12 ${activePricing === 'threeMonth' ? 'bg-blue-500 text-white' : 'bg-blue-300'} rounded-tr rounded-br`}
                        onClick={() => handlePricingChange('threeMonth')}
                    >
                        Three Months
                    </button>
                </div> */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-16 md:gap-y-32 px-4 sm:px-[60px] md:px-[120px] lg:px-[100px] mt-12">
                    <div className="flex justify-center">
                        <ServicesCard
                            image={LogoDesign}
                            title={"Logo Designing"}
                            price={activePricing === 'oneMonth' ? "8000" : "8500"}
                            months={activePricing === 'oneMonth' ? 1 : 3}
                            serviceOne={"Custom Logo Design"}
                            serviceTwo={"Brand Identity"}
                            serviceThree={"High-Resolution Formats"}
                            serviceFour={"Unlimited Revisions"}
                        />
                    </div>
                    <div className="flex justify-center">
                        <ServicesCard
                            image={ThreeDmodel}
                            title={"3D Modelling"}
                            price={activePricing === 'oneMonth' ? "8000" : "8500"}
                            months={activePricing === 'oneMonth' ? 1 : 3}
                            serviceOne={"3D Model Creation"}
                            serviceTwo={"Texture Mapping"}
                            serviceThree={"Animation"}
                            serviceFour={"High-Quality Render"}
                        />
                    </div>
                    <div className="flex justify-center">
                        <ServicesCard
                            image={VideoCreation}
                            title={"Video Creation"}
                            price={activePricing === 'oneMonth' ? "8000" : "8500"}
                            months={activePricing === 'oneMonth' ? 1 : 3}
                            serviceOne={"Script Writing"}
                            serviceTwo={"Filming"}
                            serviceThree={"Editing"}
                            serviceFour={"Motion Graphics"}
                        />
                    </div>
                    <div className="flex justify-center">
                        <ServicesCard
                            image={Infographics}
                            title={"Infographics"}
                            price={activePricing === 'oneMonth' ? "8000" : "8500"}
                            months={activePricing === 'oneMonth' ? 1 : 3}
                            serviceOne={"Custom Design"}
                            serviceTwo={"Data Visualization"}
                            serviceThree={"High-Resolution Formats"}
                            serviceFour={"Multiple Revisions"}
                        />
                    </div>
                </div>
            </section>

            <section className="mt-20 bg-[#39defff8] h-auto md:h-[600px]">
                <div className="flex flex-col md:flex-row h-full">
                    <div className="md:w-[50%] flex justify-center items-center opacity-[0.8] p-4">
                        <div>
                            <img src={Cloud} alt="" className="w-full h-auto" />
                        </div>
                    </div>
                    <div className="w-full md:w-[50%] flex flex-col justify-center items-start px-4 sm:px-4">
                        <FaqItem question="How do I start selling on Amazon?" answer="To start selling on Amazon, you need to create a seller account on Amazon Seller Central. You’ll need to provide relevant business information, set up your payment and shipping methods, and create product listings for the items you want to sell." />
                        <FaqItem question="How do I start selling on Flipkart?" answer="Similar to Amazon, you need to register as a seller on Flipkart Seller Hub. Provide your business details, set up your payment methods, and start listing your products to begin selling." />
                        <FaqItem question="How can I improve my ecommerce store's performance?" answer="Focus on optimizing your website for speed, improving user experience, implementing effective SEO strategies, and leveraging social media marketing to attract more visitors and conversions." />
                    </div>
                </div>
            </section>

            <section className="mt-20">
                <div className="w-full flex flex-col md:flex-row h-auto md:h-[400px]">
                    <div className="md:w-[50%] h-full flex-col justify-center flex items-start p-4 md:pl-32">
                        <p className="text-xl font-light text-gray-400">Boost your brand value</p>
                        <p className="italic font-light text-[30px] md:text-[40px]">
                            <span className="font-bold text-[#1a1a1d]">Marketing</span> for Everyone
                        </p>
                        <p className="text-gray-500 font-light text-sm leading-[22px]">
                            Our ecommerce services marketing is a comprehensive solution designed to drive growth and maximize success for online businesses. With our expertise in digital marketing strategies and deep understanding of the ecommerce landscape, we empower businesses to reach their target audience, increase brand visibility, and ultimately boost sales.
                        </p>
                    </div>
                    <div className="md:w-[50%] h-full flex flex-col justify-center p-4 md:pl-14 items-start">
                        <div className="font-bold">Sales Conversion</div>
                        <div className="mt-2 w-full md:w-[500px] h-5 bg-gray-100">
                            <div className="h-full w-[75%] bg-[#39defff8] relative">
                                <div className="absolute right-0 top-[-30px] font-semibold text-lg">75%</div>
                            </div>
                        </div>
                        <br />
                        <div className="font-bold">Customer Satisfaction</div>
                        <div className="mt-2 w-full md:w-[500px] h-5 bg-gray-100">
                            <div className="h-full w-[85%] bg-[#39defff8] relative">
                                <div className="absolute right-0 top-[-30px] font-semibold text-lg">85%</div>
                            </div>
                        </div>
                        <br />
                        <div className="font-bold">Revenue Growth</div>
                        <div className="mt-2 w-full md:w-[500px] h-5 bg-gray-100">
                            <div className="h-full w-[70%] bg-[#39defff8] relative">
                                <div className="absolute right-0 top-[-30px] font-semibold text-lg">70%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Graphics;
