import React from 'react';
import {Link} from 'react-router-dom'

const QualityServices = ({title,text,image,path}) => {
  return (
    <Link to={path}><div className="flex flex-col w-[280px] items-center justify-center">
        <div className="h-36 w-36 relative transition-all duration-500 top-0 cursor-pointer  hover:top-[-15px] ">
            <img src={image} className="w-full h-full" alt="" />
        </div>
        <p className="font-semibold text-[rgb(26,26,29)] text-lg mb-2 mt-5">{title}</p>
        <p className="text-gray-500 text-sm font-light w-full flex pl-2 justify-center items-center text-center">{text}</p>
    </div>
    </Link>
  )
}

export default QualityServices