import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import Services from './pages/Services'
import Contact from './pages/Contact'
import About from './pages/About'
import Digital from './pages/Services/DigitalMarketing'
import EcomManagement from './pages/Services/EcomManagement'
import Graphics from './pages/Services/Graphics'
import SEO from './pages/Services/SEO'
import Advertising from './pages/Services/Advertising'
import SocialMediaMarketing from './pages/Services/SocialMediamarketing'
import Taxation from './pages/Services/Taxation'
import WebsiteDev from './pages/Services/WebsiteDev'
import PersonalProfile from './pages/PersonalProfile'

function App() {

  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/testimonials" element={<Testimonials />} /> */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/digital-marketing" element={<Digital />} />
        <Route path="/ecom-management" element={<EcomManagement />} />
        <Route path="/graphic-design" element={<Graphics />} />
        <Route path="/seo" element={<SEO />} />
        <Route path="/advertising" element={<Advertising />} />
        <Route path="/social-media-marketing" element={<SocialMediaMarketing />} />
        <Route path="/taxation" element={<Taxation />} />
        <Route path="/website-dev" element={<WebsiteDev />} />
        <Route path="/team-member/:name" element={<PersonalProfile />} />
      </Routes>
    </Router>
  )
}

export default App
