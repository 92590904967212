import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Carousel.css';
import { Pagination, Navigation } from 'swiper/modules';
import CarouselCard from './CarouselCard';
import data from './data.json';

const Carousel = () => {
    return (
        <>
            <Swiper
                // slidesPerView={3}
                spaceBetween={0}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },
                }}
                modules={[ Navigation]}
                navigation={true}
                loop={false}
                infinite={true}
                className="mySwiper"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index} className="px-5">
                        <CarouselCard 
                            name={item.name} 
                            location={item.location} 
                            title={item.title} 
                            text={item.text} 
                            image={item.image} 
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

export default Carousel;
