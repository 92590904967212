// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/gradient.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custombg{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    object-fit: cover;
    background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/Popup.css"],"names":[],"mappings":"AAAA;IACI,mDAAyC;IACzC,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":[".custombg{\r\n    background: url('../assets/gradient.jpg');\r\n    object-fit: cover;\r\n    background-size: cover;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
