import React, { useEffect } from 'react';
import Topbar2 from '../components/TopBar2';
import QualityServices from '../components/qualityServices';
import EcomManagement from '../assets/ecommanagement.png';
import DigitalMarketing from '../assets/digitalmarketing.png';
import GraphicDeisgn from '../assets/graphicdesign.png';
import WebsiteDev from '../assets/websitedev.png';
import SocialMedia from '../assets/socialmedia.png';
import Ads from '../assets/ads.png';
import Taxation from '../assets/taxation.png';
import Seo from '../assets/seo.png';
import Strategy from '../assets/strategy.png';
import Star from '../assets/star.png';
import FaqItem from '../components/FaqItem';
import Cloud from '../assets/cloud.png';
import Footer from '../components/Footer';
import OurServices from '../assets/ourservices.jpg';
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


const Services = () => {
    useEffect(() => {
        // Initialize GSAP with ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);

        // Create GSAP animations using ScrollTrigger
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".services",
                start: "top 80%", 
                scrub:false,
            }
        });
        const t2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-2",
                start: "top 80%", 
                scrub:false,
            }
        });

        const t3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-3",
                start: "top 80%", 
                scrub:false,
            }
        });
        const t4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-5",
                start: "top 80%", 
                scrub:false,
            }
        });
        const t5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-6",
                start: "top 80%", 
                scrub:false,
            }
        });

        // Define animations
        tl.fromTo('.services', { opacity: 0,y:0 }, { opacity: 1,y:-30, duration: 1 });
        t2.fromTo('.section-2', { opacity: 0,y:0 }, { opacity: 1,y:-30, duration: 1 });
        t3.fromTo('.section-3', { opacity: 0,y:0,scale:0.8 }, { opacity: 1,y:-30,scale:1, duration: 0.7 });
        t4.fromTo('.img1', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5 });
        t4.fromTo('.img2', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t4.fromTo('.img3', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t4.fromTo('.img4', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t4.fromTo('.img5', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t5.fromTo('.section-6', { opacity: 0,y:0 }, { opacity: 1,y:-30, duration: 0.7 });

        // Ensure animations restart on component re-mount or resize
        ScrollTrigger.refresh();
    }, []);
    return (
        <>
            <Topbar2 />
            <div className="w-full h-[500px]">
                <img className="h-full w-full brightness-90" src={OurServices} alt="" />
            </div>
            <section className="flex flex-col items-center services" >
                <div className="flex flex-col items-center justify-end h-[200px] px-4 md:px-12">
                    <p className="font-light text-lg text-gray-500">We Offer</p>
                    <p className="mt-2 text-4xl sm:text-5xl font-bold text-[#1a1a1d]">Quality Services</p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 px-4 md:px-12 mt-10">
                    <QualityServices path={"/ecom-management"} title={"Ecommerce Management"} text={"Optimize your online store for increased sales and growth."} image={EcomManagement} />
                    <QualityServices path={"/website-dev"} title={"Website Development"} text={"Professional design and development for stunning websites."} image={WebsiteDev} />
                    <QualityServices path={"/graphic-design"} title={"Graphic Design"} text={"Create visually appealing designs for your brand."} image={GraphicDeisgn} />
                    <QualityServices path={"/social-media-marketing"} title={"Social Media Marketing"} text={"Enhance your brand presence on social media platforms."} image={SocialMedia} />
                    <QualityServices path={"/taxation"} title={"Taxation and Accounting"} text={"Manage tax and financial matters efficiently."} image={Taxation} />
                    <QualityServices path={"/seo"} title={"Search Engine Optimization"} text={"Improve your website's visibility in search engine results."} image={Seo} />
                    <QualityServices path={"/digital-marketing"} title={"Digital Marketing"} text={"Promote your business online effectively."} image={DigitalMarketing} />
                    <QualityServices path={"/advertising"} title={"Advertising"} text={"Create compelling advertisements for your products."} image={Ads} />
                </div>
            </section>

            <section className="mt-20 bg-[#39defff8] py-10 section-2">
                <div className="flex flex-col md:flex-row justify-between items-center px-4 md:px-12">
                <div className="md:w-[50%] flex justify-center items-center opacity-[0.8] p-4">
                        <div>
                            <img src={Cloud} alt="" className="w-full h-auto" />
                        </div>
                    </div>
                    <div className="w-full md:w-[50%] flex flex-col justify-center items-start sm:px-4">
                        <FaqItem question="How do I start selling on Amazon?" answer="To start selling on Amazon, you need to create a seller account on Amazon Seller Central. You’ll need to provide relevant business information, set up your payment and shipping methods, and create product listings for the items you want to sell." />
                        <FaqItem question="How do I start selling on Flipkart?" answer="Similar to Amazon, you need to register as a seller on Flipkart Seller Hub. Provide your business details, set up your payment methods, and start listing your products to begin selling." />
                        <FaqItem question="How can I improve my ecommerce store's performance?" answer="Focus on optimizing your website for speed, improving user experience, implementing effective SEO strategies, and leveraging social media marketing to attract more visitors and conversions." />
                    </div>
                </div>
            </section>

            <section className="py-20 section-6">
                <div className="flex flex-col md:flex-row justify-between items-center px-4 md:px-12">
                    <div className="w-full md:w-[50%] flex flex-col justify-center items-start">
                        <p className="text-xl font-light text-gray-400">Boost your brand value</p>
                        <p className="italic font-light text-[40px] "><span className="font-bold text-[#1a1a1d]">Marketing</span> for Everyone</p>
                        <p className="text-gray-500 font-light text-sm my-5 sm:my-0 leading-[22px]">
                            Our ecommerce services marketing is a comprehensive solution designed to drive growth and maximize success for online businesses. With our expertise in digital marketing strategies and deep understanding of the ecommerce landscape, we empower businesses to reach their target audience, increase brand visibility, and ultimately boost sales.
                        </p>
                    </div>
                    <div className="w-full md:w-[50%] flex flex-col items-start px-4">
                        <div className="font-bold mb-4">Sales Conversion</div>
                        <div className="w-full h-5 bg-gray-100 mb-4">
                            <div className="h-full w-[75%] bg-[#39defff8] relative">
                                <div className="absolute right-0 top-[-30px] font-semibold text-lg">75%</div>
                            </div>
                        </div>
                        <div className="font-bold mb-4">Customer Satisfaction</div>
                        <div className="w-full h-5 bg-gray-100 mb-4">
                            <div className="h-full w-[85%] bg-[#39defff8] relative">
                                <div className="absolute right-0 top-[-30px] font-semibold text-lg">85%</div>
                            </div>
                        </div>
                        <div className="font-bold mb-4">Revenue Growth</div>
                        <div className="w-full h-5 bg-gray-100 mb-4">
                            <div className="h-full w-[70%] bg-[#39defff8] relative">
                                <div className="absolute right-0 top-[-30px] font-semibold text-lg">70%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Services;
