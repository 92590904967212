import React, { useState, useEffect } from 'react';
import { MdMailOutline } from "react-icons/md";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { FaInstagram, FaX } from "react-icons/fa6";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, useLocation } from 'react-router-dom';
import Logo from '../assets/logo.png';
import Map from '../assets/map.png';
// import {gsap} from 'gsap';
import { FaArrowRight } from 'react-icons/fa'; // Example icon


const TopBar = () => {
    const location = useLocation();
    const [activePage, setActivePage] = useState(location.pathname);
    const [isSticky, setIsSticky] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hamBurger, setHamBurger] = useState(false);
    const [hamBurger2, setHamBurger2] = useState(false);

    const handleNavigation = (path) => {
        setActivePage(path);
        setIsDropdownOpen(false);  // Close dropdown on navigation
    };

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            setIsSticky(currentScroll > 100);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [servicesExpanded, setServicesExpanded] = useState(false);

    const toggleServices = () => {
        setServicesExpanded(!servicesExpanded);
    };



    return (
        <>
            <div className={`h-20 mt-1  z-[5000] shadow flex bg-[#ffffff12] items-center relative justify-between ${isSticky ? 'sticky top-0 bg-white' : ''} `}>
                <div onClick={() => setHamBurger(!hamBurger)} className="lg:hidden z-[5000] pl-5 lg:pl-0">
                    {hamBurger ? <FaX className="text-black text-xl" /> : <RxHamburgerMenu className="text-black text-3xl" />}
                </div>
                <Link to="/"><div className="h-16 w-16 rounded-lg overflow-hidden top-bar ml-[-20px] sm:ml-5">
                    <img src={Logo} className="h-full w-full invert-[100%]" alt="" />
                </div></Link>
                <div className="hidden lg:flex h-full home-li">
                    <ul className="flex transition-all duration-300 uppercase font-bold text-[#303037ea] text-sm h-full justify-center items-center">
                        <li className={`px-5 h-full flex items-center ${activePage === '/' && 'border-b-4 rounded border-[#252529]'}`}>
                            <Link to="/" onClick={() => handleNavigation('/')}>Home</Link>
                        </li>
                        <li
                            className={`px-5 h-full flex items-center relative ${activePage === '/services' && 'border-b-4 rounded border-[#1a1a1d]'} z-[5000]`}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="/services" onClick={() => handleNavigation('/services')}>Our services</Link>
                            {isDropdownOpen && (
                                <div className="absolute top-full left-0 w-56 bg-white shadow-lg py-3 transition-all duration-300 z-[5010]">
                                    <ul className="text-gray-800 text-[13px] font-normal capitalize">
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/ecom-management" onClick={() => handleNavigation('/service1')}>
                                                    Ecommerce Management
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/website-dev" onClick={() => handleNavigation('/service2')}>
                                                    Website Development
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/taxation" onClick={() => handleNavigation('/service3')}>
                                                    Taxation and accounting
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/graphic-design" onClick={() => handleNavigation('/service3')}>
                                                    Graphic Design
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/advertising" onClick={() => handleNavigation('/service3')}>
                                                    Advertising
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/digital-marketing" onClick={() => handleNavigation('/digital-marketing')}>
                                                    Digital Marketing
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/seo" onClick={() => handleNavigation('/service3')}>
                                                    Search Engine Optimization
                                                </Link>
                                            </span>
                                        </li>
                                        <li className="relative px-4 py-2 hover:text-blue-700 flex items-center space-x-2 cursor-pointer group">
                                            <FaArrowRight className="absolute left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                            <span className="ml-8 group-hover:ml-5 transition-all duration-300">
                                                <Link to="/social-media-marketing" onClick={() => handleNavigation('/service3')}>
                                                    Social Media Marketing
                                                </Link>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li className={`px-5 h-full flex items-center ${activePage === '/about' && 'border-b-4 rounded border-[#232329]'}`}>
                            <Link to="/about" onClick={() => handleNavigation('/about')}>About us</Link>
                        </li>
                        <li className={`px-5 h-full flex items-center ${activePage === '/contact' && 'border-b-4 rounded border-[#1a1a1d]'}`}>
                            <Link to="/contact" onClick={() => handleNavigation('/contact')}>Contact us</Link>
                        </li>
                        {/* <li className={`px-5 h-full flex items-center ${activePage === '/testimonials' && 'border-b-4 rounded border-[#1a1a1d]'}`}>
                        <Link to="/testimonials" onClick={() => handleNavigation('/testimonials')}>Testimonials</Link>
                    </li> */}
                    </ul>
                </div>
                <div className="hidden lg:flex relative z-[5000]" onClick={() => setHamBurger2(!hamBurger2)}>
                    {/* <CiSearch className="text-[#1a1a1d] font-bold" size={26} /> */}
                    {/* {hamBurger2 ? <FaX className="text-white cursor-pointer font-bold ml-3" size={20} /> : <RxHamburgerMenu className="text-[#1a1a1d] cursor-pointer font-bold ml-3" size={26} />} */}


                </div>

                <div className="lg:hidden"></div>

                <div className={`w-full bg-white z-[5020] flex flex-col px-10 py-4 absolute ${hamBurger ? 'top-[80px] opacity-100 block' : 'opacity-0 hidden top-0'} transition-all duration-300`}>
                    <Link to="/"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Home</p></Link>
                    <div>
                        <div className="flex justify-between items-center border-b py-3 cursor-pointer" onClick={toggleServices}>
                            <p className="mb-2 text-xs font-light hover:text-blue-500">Our Services</p>
                            <span className="text-xs">{servicesExpanded ? '-' : '+'}</span>
                        </div>
                        {servicesExpanded && (
                            <div className="pl-4">
                                <Link to="/ecom-management"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Ecommerce Management</p></Link>
                                <Link to="/website-dev"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Website Development</p></Link>
                                <Link to="/taxation"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Taxation and accounting</p></Link>
                                <Link to="/graphic-design"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Graphic Design</p></Link>
                                <Link to="/advertising"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Advertising</p></Link>
                                <Link to="/digital-marketing"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Digital Marketing</p></Link>
                                <Link to="/seo"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Search Engine optimization</p></Link>
                                <Link to="/social-media-marketing"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Social Media Marketing</p></Link>
                            </div>
                        )}
                    </div>
                    <Link to="/about"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">About Us</p></Link>
                    <Link to="/contact"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Contact Us</p></Link>
                    {/* <Link to="/testimonials"><p className="mb-2 text-xs font-light border-b py-3 hover:text-blue-500 cursor-pointer">Testimonials</p></Link> */}
                </div>

                {/* <div className={`absolute bg-[#27272c] transition-all duration-500  top-[0] h-screen w-[350px] ${hamBurger2 ? 'right-0' : 'right-[-100%]'} flex flex-col p-10 pt-20 text-white text-sm`}>
                    <p className="font-light  leading-[28px]">Whether you have a specific request, need more information about our services, or simply want to provide feedback, we encourage you to reach out to us. Our dedicated customer support team is committed to providing prompt and helpful assistance to ensure your satisfaction.</p>
                    <p className=" mt-6 text-[15px]">Contact us now +91-72248 13564</p>
                    <p className=" mt-2 text-[13px]">ecommercebusinesssolutionhub@gmail.com</p>
                    <div className="flex mt-7">
                        <FaInstagram className="mr-3 " size={19} />
                        <FaFacebookF className="mr-3" size={19} />
                        <FaTwitter className="" size={19} />
                    </div>
                </div> */}
            </div>

        </>
    );
};

export default TopBar;
