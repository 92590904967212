import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FaqItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-full sm:w-[450px] flex flex-col mb-4">
            <div
                onClick={toggleOpen}
                className={`flex justify-between items-center cursor-pointer text-md font-semibold px-3 py-5  transition-all duration-500 ${
                    isOpen ? 'bg-white text-black' : 'bg-[#ffffff27] text-white'
                }`}
            >
                <div>{question}</div>
                <div className="ml-2">{isOpen ? <FaMinus /> : <FaPlus />}</div>
            </div>
            {isOpen && (
                <p className="mt-2 text-white text-sm mb-2 px-4">
                    {answer}
                </p>
            )}
        </div>
    );
};

export default FaqItem;
