import React,{useEffect} from 'react';
import Topbar2 from '../components/TopBar2';
import Footer from '../components/Footer';
import AboutUs from '../assets/aboutus.png';

import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';


const About = () => {
    useEffect(() => {
        // Initialize GSAP with ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);

        // Create GSAP animations using ScrollTrigger
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".services",
                start: "top 80%", 
                scrub:false,
            }
        });
        const t2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-2",
                start: "top 80%", 
                scrub:false,
            }
        });

        const t3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-3",
                start: "top 80%", 
                scrub:false,
            }
        });
        const t4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-5",
                start: "top 80%", 
                scrub:false,
            }
        });
        const t5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-6",
                start: "top 80%", 
                scrub:false,
            }
        });

        // Define animations
        tl.fromTo('.services', { opacity: 0,y:0 }, { opacity: 1,y:-30, duration: 1 });
        t2.fromTo('.section-2', { opacity: 0,y:0 }, { opacity: 1,y:-30, duration: 1 });
        t3.fromTo('.section-3', { opacity: 0,y:0,scale:0.8 }, { opacity: 1,y:-30,scale:1, duration: 0.7 });
        t4.fromTo('.img1', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5 });
        t4.fromTo('.img2', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t4.fromTo('.img3', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t4.fromTo('.img4', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t4.fromTo('.img5', { opacity: 0, scale:0.8 }, { opacity: 1, scale:1, duration: 0.5,delay:0.01 });
        t5.fromTo('.section-6', { opacity: 0,y:0 }, { opacity: 1,y:-30, duration: 0.7 });

        // Ensure animations restart on component re-mount or resize
        ScrollTrigger.refresh();
    }, []);
    return (
        <>
            <Topbar2 />
            <div className="w-full h-[500px]">
                <img className="h-full w-full object-cover brightness-90" src={AboutUs} alt="" />
            </div>
            <section className="flex flex-col items-center px-5 md:px-0 section-2">
                <div className="flex flex-col w-full max-w-2xl items-center justify-end h-auto my-10">
                    <p className="font-light text-lg text-gray-500">Welcome to</p>
                    <p className="mt-2 text-5xl font-bold text-[#1a1a1d] text-center">Ecommerce Business <span className="font-normal italic">&nbsp;Solutions Hub</span></p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                    <p className="w-full leading-[22px] text-sm font-light text-center mt-6">
                    As an E-commerce services provider, we are committed to offering you the highest quality services. Our team works tirelessly to ensure that your business receives top-notch solutions to help you succeed in the ever-evolving online marketplace. 
                    </p>
                </div>

                <div className="flex flex-col w-full max-w-2xl items-center justify-end h-auto my-10">
                    <p className="mt-2 text-5xl font-bold text-[#1a1a1d] text-center">Why<span className="font-normal italic">&nbsp;Us?</span></p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                    <p className="w-full leading-[22px] text-sm font-light text-center mt-6">
                    As an E-commerce services provider, our values revolve around providing you with the best services to help you grow your business. We are dedicated to supporting your success and ensuring that your experience with us is seamless and rewarding. Our team is committed to delivering top-notch solutions that cater to your specific needs and goals. Whether it's optimizing your online presence, enhancing customer engagement, or streamlining your operations, we are here to empower you every step of the way. Trust in us to be your partner in achieving your business objectives and maximizing your potential in the ever-evolving E-commerce landscape.
                    </p>
                </div>

                <div className="flex flex-col w-full max-w-2xl items-center justify-end h-auto my-10">
                    <p className="mt-2 text-5xl font-bold text-[#1a1a1d] text-center">About Us<span className="font-normal italic"></span></p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                    <p className="w-full leading-[22px] text-sm font-light text-center mt-6">
                    As an E-commerce services provider, our vision is to offer you top-notch services that will not only meet your needs but also help you expand and thrive in the competitive online marketplace. We are dedicated E-commerce services providers committed to offering you top-notch services designed to propel your business to new heights. Our primary goal is to support and enhance your online presence, enabling you to reach a broader audience and achieve sustainable growth. With our the best tools, strategies, and support to elevate your business to new heights. Our team is committed to ensuring that you receive exceptional service and guidance every step of the way. With our expertise and tailored solutions, we strive to streamline your operations, maximize efficiency, and ultimately boost your bottom line. Trust us to be your partner in success, as we work tirelessly to deliver exceptional services that align with your business goals and objectives. Let us empower your E-commerce venture and help you unlock its full potential.
                    </p>
                </div>

                <div className="flex flex-col w-full max-w-2xl items-center justify-end h-auto my-10">
                    <p className="mt-2 text-5xl font-bold text-[#1a1a1d] text-center">Our Priority<span className="font-normal italic"></span></p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                    <p className="w-full leading-[22px] text-sm font-light text-center mt-6">
                    We prioritize convenience and efficiency for our clients. Our vision is to offer a seamless platform where all business products are easily accessible in one place, allowing you to streamline your operations and focus on what you do best. By centralizing resources and offering a diverse range of services, we aim to simplify the complexities of E-commerce business management.
                    </p>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default About;