import React,{useState,useEffect} from 'react';
import { FaStar } from "react-icons/fa";
import Colourspop from '../assets/testimonials/colourspop.png';
import Dsrefubrish from '../assets/testimonials/dsrefubrish.jpg';
import Erexia from '../assets/testimonials/erexia.png';
import Kr from '../assets/testimonials/kr.jpg';
import TrcExpo from '../assets/testimonials/trcexpo.jpg';
import Wagreoutfit from '../assets/testimonials/wagreoutfit.jpg';
import Vintech from '../assets/testimonials/vintech.png';
import Wmh from '../assets/testimonials/wmh.png';
import  Srirudh from '../assets/testimonials/srirudh.webp';
import kasid from '../assets/testimonials/kasid.jpeg';

const CarouselCard = ({ name, location, title, text, image }) => {
    const [profileImage, setProfileImage] = useState(null);

    useEffect(() => {
        if (image == "colourspop") {
            setProfileImage(Colourspop);
        }
        else if (image == "dsrefubrish") {
            setProfileImage(Dsrefubrish);
        }
        else if (image == "evexia") {
            setProfileImage(Erexia);
        }
        else if (image == "kr") {
            setProfileImage(Kr);
        }
        else if (image == "trcexpo") {
            setProfileImage(TrcExpo);
        }
        else if (image == "wagreoutfit") {
            setProfileImage(Wagreoutfit);
        }
        else if (image == "vintech") {
            setProfileImage(Vintech);
        }
        else if (image == "wmh") {
            setProfileImage(Wmh);
        }
        else if (image == "srirudh") {
            setProfileImage(Srirudh);
        }
        else if (image == "kasid") {
            setProfileImage(kasid);
        }
    }, [image]);

    return (
        <div className="flex flex-col  min-w-[320px] sm:min-w-[400px] h-[400px] rounded-xl shadow border p-6">
            <div className="flex">
                <div className="rounded-full overflow-hidden h-24 w-24"><img className="h-full w-full" src={profileImage} alt="" /></div>
                <div className="flex flex-col justify-center ml-8 items-start text-start">
                    <p className="font-semibold text-xl">{name}</p>
                    <p className="mt-2 text-sm ">{title}</p>
                    <p className="text-sm text-gray-500">{location}</p>
                    <div className="flex mt-1">
                        <FaStar size={14} className="text-yellow-500" />
                        <FaStar size={14} className="text-yellow-500" />
                        <FaStar size={14} className="text-yellow-500" />
                        <FaStar size={14} className="text-yellow-500" />
                        <FaStar size={14} className="text-yellow-500" />
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <p className="text-sm text-gray-600 text-start">{text}</p>
            </div>
        </div>
    )
}

export default CarouselCard