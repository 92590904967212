import React, { useState, useEffect } from 'react';
import TopBar2 from '../components/TopBar2';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import Priyanshu from '../assets/TeamMembers/priyanshu.jpg';
import Aditya from '../assets/TeamMembers/aditya.jpg';
import Shrishti from '../assets/TeamMembers/shrishti.jpg';
import Anvita from '../assets/TeamMembers/anvika.jpg';
import Ravi from '../assets/TeamMembers/ravi.jpg';
import Vinay from '../assets/TeamMembers/vinay.jpg';
import { FaCalendar } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { CiBookmark } from "react-icons/ci";
import { FaHome } from "react-icons/fa";

const PersonalProfile = () => {
    const [name, setName] = useState(null);
    const state = useParams();
    useEffect(() => {
        setName(state.name);
    }, [state]);

    return (
        <>
            <TopBar2 />
            <div className="w-full flex items-center h-40 bg-gray-200 uppercase text-5xl pl-4 md:pl-20 text-[#1a1a1ddf] font-bold text-center md:text-left">
                {name?.split("-").join(" ")}
            </div>
            {name == "ravi-kol" && <div className="flex flex-col md:flex-row mt-10 mb-20 px-4 md:px-20">
                <div className="md:w-[50%] flex justify-center md:justify-end items-center pr-0 md:pr-10 mb-10 md:mb-0">
                    <div className="w-[300px] h-[350px] md:w-[450px] md:h-[500px]">
                        <img src={Ravi} className="h-full w-full object-cover" alt="" />
                    </div>
                </div>
                <div className="md:w-[50%] flex flex-col items-center md:items-start text-center md:text-left">
                    <p className="font-bold text-2xl uppercase ">{name.split('-').join(' ')}</p>
                    <p className="text-gray-500 mt-1">Founder</p>
                    <div className="flex items-center mt-8 mb-6">
                        <FaCalendar className="text-gray-700"  />
                        <p className="ml-2">Born on:</p>
                        <p className="text-sm ml-2 text-gray-500">18 February 1996</p>
                    </div>
                    <div className="flex items-center mt-2  mb-6">
                        <CiBookmark className="text-gray-700"  />
                        <p className="ml-2">Education:</p>
                        <p className="text-sm ml-2 text-gray-500">B.E</p>
                    </div>
                    <div className="flex items-center mt-2  mb-6">
                        <FaHome className="text-gray-700"  />
                        <p className="ml-2">Address:</p>
                        <p className="text-sm ml-2 text-gray-500">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <MdEmail className="text-gray-700"  />
                        <p className="ml-2">Email:</p>
                        <p className="text-sm ml-2 text-gray-500">ecommercebusinesssolutionhub@gmail.com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <FaPhoneSquareAlt className="text-gray-700"  />
                        <p className="ml-2">Phone:</p>
                        <p className="text-sm ml-2 text-gray-500">7909702051</p>
                    </div>
                </div>
            </div>}
            {name == "vinay-kumar-chadar" && <div className="flex flex-col md:flex-row mt-10 mb-20 px-4 md:px-20">
                <div className="md:w-[50%] flex justify-center md:justify-end items-center pr-0 md:pr-10 mb-10 md:mb-0">
                    <div className="w-[300px] h-[350px] md:w-[450px] md:h-[500px]">
                        <img src={Vinay} className="h-full w-full object-cover" alt="" />
                    </div>
                </div>
                <div className="md:w-[50%] flex flex-col items-center md:items-start text-center md:text-left">
                    <p className="font-bold text-2xl uppercase ">{name.split('-').join(' ')}</p>
                    <p className="text-gray-500 mt-1">Co-Founder</p>
                    <div className="flex items-center mt-8 mb-6">
                        <FaCalendar  className="text-gray-700" />
                        <p className="ml-2">Born on:</p>
                        <p className="text-sm ml-2 text-gray-500">25 May 1992</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <CiBookmark className="text-gray-700"  />
                        <p className="ml-2">Education:</p>
                        <p className="text-sm ml-2 text-gray-500">B.Com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <FaHome  className="text-gray-700" />
                        <p className="ml-2">Address:</p>
                        <p className="text-sm ml-2 text-gray-500">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <MdEmail className="text-gray-700"  />

                        <p className="ml-2">Email:</p>
                        <p className="text-sm ml-2 text-gray-500">ecommercebusinesssolutionhub@gmail.com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <FaPhoneSquareAlt className="text-gray-700"  />
                        <p className="ml-2">Phone:</p>
                        <p className="text-sm ml-2 text-gray-500">7909702051</p>
                    </div>
                </div>
            </div>}
            {name == "priyanshu-koshta" && <div className="flex flex-col md:flex-row mt-10 mb-20 px-4 md:px-20">
                <div className="md:w-[50%] flex justify-center md:justify-end items-center pr-0 md:pr-10 mb-10 md:mb-0">
                    <div className="w-[300px] h-[350px] md:w-[450px] md:h-[500px] overflow-hidden">
                        <img src={Priyanshu} className="h-[130%] w-full " alt="" />
                    </div>
                </div>
                <div className="md:w-[50%] flex flex-col items-center md:items-start text-center md:text-left">
                    <p className="font-bold text-2xl uppercase ">{name.split('-').join(' ')}</p>
                    <p className="text-gray-500 mt-1">Senior Amazon/Flipkart Manager</p>
                    <p className="mt-5 font-bold text-xl mb-5">About</p>
                    <p className="text-[13px] font-light w-[90%] md:w-[420px]">
                        Hello Everyone,
                        I am Priyanshu Koshta, a young employee playing the role of a Senior Account Manager in the Amazon Department of EBSH team. I started my career with the EIBS team, where I have learned all kinds of skills like management, communication, support, etc. And now I am working for the E-commerce Business Solution Hub. Dear Sellers, I will always be with you for the growth of your e-commerce business. Hope you join our leading company E-commerce Business Solution Hub, we will always be there for your business growth.
                    </p>
                    <div className="flex items-center mt-8 mb-4">
                        <FaCalendar className="text-gray-700"  />
                        <p className="ml-2 text-sm">Born on:</p>
                        <p className="text-sm ml-2 text-gray-500">7 July 2002</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <CiBookmark  className="text-gray-700" />
                        <p className="ml-2 text-sm">Education:</p>
                        <p className="text-sm ml-2 text-gray-500">B.Com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <p className="ml-2 text-sm">Experience:</p>
                        <p className="text-sm ml-2 text-gray-500">4 years</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaHome  className="text-gray-700" />
                        <p className="ml-2 text-sm">Address:</p>
                        <p className="text-sm ml-2 text-gray-500">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <MdEmail  className="text-gray-700" />

                        <p className="ml-2 text-sm">Email:</p>
                        <p className="text-sm ml-2 text-gray-500">ecommercebusinesssolutionhub@gmail.com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-6">
                        <FaPhoneSquareAlt  className="text-gray-700" />
                        <p className="ml-2 text-sm">Phone:</p>
                        <p className="text-sm ml-2 text-gray-500">7909702051</p>
                    </div>
                </div>
            </div>}

            {name == "aditya-namdeo" && <div className="flex flex-col md:flex-row mt-10 mb-20 px-4 md:px-20">
                <div className="md:w-[50%] flex justify-center md:justify-end items-center pr-0 md:pr-10 mb-10 md:mb-0">
                    <div className="w-[300px] h-[350px] md:w-[450px] md:h-[500px]">
                        <img src={Aditya} className="h-full w-full object-cover" alt="" />
                    </div>
                </div>
                <div className="md:w-[50%] flex flex-col items-center md:items-start text-center md:text-left">
                    <p className="font-bold text-2xl uppercase ">{name.split('-').join(' ')}</p>
                    <p className="text-gray-500 mt-1">Senior Flipkart Account Manager</p>
                    <p className="mt-5 font-bold text-xl mb-5">About</p>
                    <p className="text-[13px] font-light w-[90%] md:w-[420px]">
                        Hello, I am Aditya working as an Sr. Account Manager for more than 2 years at a E-Commerce Business Solution Hub, and currently I am also working as a team leader. It’s an honor to work with such nice staff with very helpful and loving nature people. Before I was hesitant while communicating with the seller and other staff they supported me and boosted my confidence. now I am able to talk freely to anyone, here I have learned a lot of things thanks to your kind support team
                    </p>
                    <div className="flex items-center mt-8 mb-4">
                        <FaCalendar className="text-gray-700"  />
                        <p className="ml-2 text-sm">Born on:</p>
                        <p className="text-sm ml-2 text-gray-500">26 January 2002</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <CiBookmark  className="text-gray-700" />
                        <p className="ml-2 text-sm">Education:</p>
                        <p className="text-sm ml-2 text-gray-500">B.Com, MBA</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <p className="ml-2 text-sm">Experience:</p>
                        <p className="text-sm ml-2 text-gray-500">3 years</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaHome className="text-gray-700"  />
                        <p className="ml-2 text-sm">Address:</p>
                        <p className="text-sm ml-2 text-gray-500">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <MdEmail className="text-gray-700"  />
                        <p className="ml-2 text-sm">Email:</p>
                        <p className="text-sm ml-2 text-gray-500">ecommercebusinesssolutionhub@gmail.com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaPhoneSquareAlt className="text-gray-700"  />
                        <p className="ml-2 text-sm">Phone:</p>
                        <p className="text-sm ml-2 text-gray-500">7909702051</p>
                    </div>
                </div>
            </div>}

            {name == "shristi-jha" && <div className="flex flex-col md:flex-row mt-10 mb-20 px-4 md:px-20">
                <div className="md:w-[50%] flex justify-center md:justify-end items-center pr-0 md:pr-10 mb-10 md:mb-0">
                    <div className="w-[300px] h-[350px] md:w-[450px] md:h-[500px] overflow-hidden">
                        <img src={Shrishti} className="h-[140%] w-full relative" alt="" />
                    </div>
                </div>
                <div className="md:w-[50%] flex flex-col items-center md:items-start text-center md:text-left">
                    <p className="font-bold text-2xl uppercase ">{name.split('-').join(' ')}</p>
                    <p className="text-gray-500 mt-1">Senior Amazon Account Manager</p>
                    <p className="mt-5 font-bold text-xl mb-5">About</p>
                    <p className="text-[13px] font-light w-[90%] md:w-[420px]">
                        Hello, I am Shristi Jha Sr. Amazon Account Manager. Thank you E-Commerce Business Solution for giving me a great opportunity to serve your company, it was a great experience with a responsible and dedicated team, I learned a lot which helped me to uplift myself and improve my talent, I Got to say, this is a great place to develop our skills. Now I proudly say I am a confident Manager of EBSH.
                    </p>
                    <div className="flex items-center mt-8 mb-4">
                        <FaCalendar  className="text-gray-700" />
                        <p className="ml-2 text-sm">Born on:</p>
                        <p className="text-sm ml-2 text-gray-500">12 July 2002</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <CiBookmark  className="text-gray-700" />
                        <p className="ml-2 text-sm">Education:</p>
                        <p className="text-sm ml-2 text-gray-500">BBA</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <p className="ml-2 text-sm">Experience:</p>
                        <p className="text-sm ml-2 text-gray-500">4 years</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaHome className="text-gray-700"  />
                        <p className="ml-2 text-sm">Address:</p>
                        <p className="text-sm ml-2 text-gray-500">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <MdEmail className="text-gray-700"  />
                        <p className="ml-2 text-sm">Email:</p>
                        <p className="text-sm ml-2 text-gray-500">ecommercebusinesssolutionhub@gmail.com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaPhoneSquareAlt className="text-gray-700"  />
                        <p className="ml-2 text-sm">Phone:</p>
                        <p className="text-sm ml-2 text-gray-500">7909702051</p>
                    </div>
                </div>
            </div>}

            {name == "anvita-haldkar" && <div className="flex flex-col md:flex-row mt-10 mb-20 px-4 md:px-20">
                <div className="md:w-[50%] flex justify-center md:justify-end items-center pr-0 md:pr-10 mb-10 md:mb-0">
                    <div className="w-[300px] h-[350px] overflow-hidden md:w-[450px] md:h-[500px]">
                        <img src={Anvita} className="h-[150%] top-[-50px] w-full relative" alt="" />
                    </div>
                </div>
                <div className="md:w-[50%] flex flex-col items-center md:items-start text-center md:text-left">
                    <p className="font-bold text-2xl uppercase ">{name.split('-').join(' ')}</p>
                    <p className="text-gray-500 mt-1">Amazon Account Manager</p>
                    <p className="mt-5 font-bold text-xl mb-5">About</p>
                    <p className="text-[13px] font-light w-[90%] md:w-[420px]">
                        Hello, I am Anvita Haldkar, working as a amazon account manager at E-Commerce Business Solution. It’s an honor to work with such nice colleagues, very helpful, friendly & supportive in nature.Thank you to the company owner Mr. Ravi Sir who are always there for my concern and guided me. Here I have learned a lot of things. Thanks for your kind & support.
                    </p>
                    <div className="flex items-center mt-8 mb-4">
                        <FaCalendar className="text-gray-700"  />
                        <p className="ml-2 text-sm">Born on:</p>
                        <p className="text-sm ml-2 text-gray-500">11 October 2001</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <CiBookmark  className="text-gray-700" />
                        <p className="ml-2 text-sm">Education:</p>
                        <p className="text-sm ml-2 text-gray-500">MCA</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaHome className="text-gray-700"  />
                        <p className="ml-2 text-sm">Address:</p>
                        <p className="text-sm ml-2 text-gray-500">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <MdEmail className="text-gray-700"  />
                        <p className="ml-2 text-sm">Email:</p>
                        <p className="text-sm ml-2 text-gray-500">ecommercebusinesssolutionhub@gmail.com</p>
                    </div>
                    <div className="flex items-center mt-2 mb-4">
                        <FaPhoneSquareAlt className="text-gray-700" />
                        <p className="ml-2 text-sm">Phone:</p>
                        <p className="text-sm ml-2 text-gray-500">7909702051</p>
                    </div>
                </div>
            </div>}
            <Footer />
        </>
    );
}

export default PersonalProfile;
