import React from 'react'
import { FaFacebookF, FaArrowRight, FaArrowUp } from "react-icons/fa";
import { FaInstagram,FaLinkedin } from "react-icons/fa6";
import { FaTwitter,FaPhone } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import {Link} from 'react-router-dom'


const Footer = () => {
    return (
        <div className="flex flex-col relative overflow-hidden">
            <div className="bg-[#242428] w-full pt-20 flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-1/3 px-4 md:px-14 mb-8 md:mb-0">
                    <p className="font-bold text-white text-2xl mb-2">About Us</p>
                    <div className="bg-blue-400 w-10 h-1 mb-7"></div>
                    <p className="text-white font-light text-[16px]">At Ecommerce Business Solutions Hub, we are a group of determined individuals belonging from various fields such as E-commerce, Consulting and Information Technology. Experienced professionals from top – notch, industries coming together to bring the best of the digital world at your disposal. We aim at making, our customers’ online business a great success.</p>
                    <div className="flex mt-5 mb-20">
                        <a href="https://www.instagram.com/ebsh_2024"><FaInstagram className="text-white mr-3 cursor-pointer " /></a>
                        <a href="https://x.com/EBSH04"><FaTwitter className="text-white mr-3 cursor-pointer " /></a>
                        <a href="https://www.facebook.com/people/E-Commerce-Business-Solution-Hub/61562861251078/"><FaFacebookF className="text-white mr-3 cursor-pointer " /></a>
                        <a href="https://www.linkedin.com/in/ravi-kol-966254319?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"><FaLinkedin className="text-white mr-3 cursor-pointer " /></a>
                    </div>
                </div>

                <div className="flex flex-col w-full md:w-1/3 px-4 md:px-14 mb-8 md:mb-0">
                    <p className="font-bold text-white text-2xl mb-2">Quick Links</p>
                    <div className="bg-blue-400 w-10 h-1 mb-7"></div>
                    <div className="flex w-full justify-between pr-0 md:pr-32">
                        <div className="flex flex-col">
                            <Link to="/"><p className="text-white text-sm cursor-pointer mt-4">Main Home</p></Link>
                            <Link to="/services"><p className="text-white text-sm cursor-pointer mt-4">Our Services</p></Link>
                        </div>
                        <div className="flex flex-col">
                            <Link to="/about"><p className="text-white text-sm cursor-pointer mt-4">About Us</p></Link>
                            <Link to="/contact"><p className="text-white text-sm cursor-pointer mt-4">Contact Us</p></Link>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full md:w-1/3 px-4 md:px-14">
                    <p className="font-bold text-white text-2xl mb-2">Contact Us</p>
                    <div className="bg-blue-400 w-10 h-1 mb-7"></div>
                    <p className="text-white font-light text-[16px]">Address: 2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004 </p>
                    <p className="text-xs sm:text-[15px]  text-white mt-7 flex items-center"><FaPhone className="mr-2"/> 7909702051</p>
                    <p className="text-xs sm:text-[15px] text-white mt-2 mb-2 flex items-center"><MdOutlineMail className="mr-2" size={20} />ecommercebusinesssolutionhub@gmail.com</p>
                </div>
            </div>

            <div className="w-full h-20 bg-[#242428] flex text-[#ffffff7e] font-light text-sm justify-center items-center">
                Copyright&copy; 2024 | Made with ❤️ by <a href="https://innogative.in" className="font-semibold hover:text-white">&nbsp;Innogative</a>
            </div>

            <div className="absolute bottom-14 sm:bottom-5 right-5 md:right-10 text-white bg-blue-400 h-10 w-10 md:h-14 md:w-14 flex items-center justify-center ">
                <button className="h-full w-full cursor-pointer flex items-center justify-center"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                ><FaArrowUp /></button>
            </div>
        </div>
    )
}

export default Footer
