import React from 'react';
import TopBar2 from '../components/TopBar2';
import Footer from '../components/Footer';
import './Contact.css'
import ContactUs from '../assets/contactus.png'

const Contact = () => {
  return (
    <>
      <TopBar2 />
      <div className="contact h-screen flex flex-col sm:flex-row w-full pb-20">
        <div className="w-full sm:w-[50%] h-full flex justify-center sm:justify-end  items-center sm:items-end mt-20 sm:mt-0">
          <div className="h-[200px] sm:h-[400px] w-[200px] sm:w-[400px] ">
            <img src={ContactUs} alt="" />
          </div>
        </div>
        <div className="w-full sm:w-[50%] h-full flex flex-col justify-end items-start text-white pb-20 ">
          <div className="sm:w-[500px] pl-4 sm:pl-10">
            <p className="text-2xl sm:text-5xl font-bold mb-5 uppercase">Contact us now</p>
            <p className="font-light text-sm sm:text-md mb-5">Whether you have a specific request, need more information about our services, or simply want to provide feedback, we encourage you to reach out to us. Our dedicated customer support team is committed to providing prompt and helpful assistance to ensure your satisfaction.</p>
            <p className=" font-semibold mb-5">2nd Floor, Gokuldham building, new Ram Nagar, Adhartal Jabalpur, Madhya Pradesh 482004</p>
            <p className="font-semibold mb-5">ecommercebusinesssolutionhub@gmail.com</p>
            <p className="font-semibold ">+917909702051</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contact