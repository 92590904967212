import React, { useEffect } from 'react';
import { FaInstagram, FaFacebookF, FaArrowRight, FaArrowUp, FaTwitter } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import QualityServices from '../components/qualityServices';
import EcomManagement from '../assets/ecommanagement.png';
import DigitalMarketing from '../assets/digitalmarketing.png';
import GraphicDeisgn from '../assets/graphicdesign.png';
import WebsiteDev from '../assets/websitedev.png';
import SocialMedia from '../assets/socialmedia.png';
import Ads from '../assets/ads.png';
import Taxation from '../assets/taxation.png';
import Seo from '../assets/seo.png';
import Strategy from '../assets/strategy.png';
import Star from '../assets/star.png';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import Strategy2 from '../assets/strategy2.png';
import Results from '../assets/results.png';
import Research from '../assets/research.png';
import Optimizaiton from '../assets/optimization.png';
import Planning from '../assets/planning.png';
import Long1 from '../assets/long1.png';
import Long2 from '../assets/long2.png';
import Long3 from '../assets/long3.png';
import Grow from '../assets/grow.png';
import Vinay from '../assets/TeamMembers/vinay.jpg';
import Shrishti from '../assets/TeamMembers/shrishti.jpg';
import Anvita from '../assets/TeamMembers/anvika.jpg';
import Ravi from '../assets/TeamMembers/ravi.jpg';
import Priyanshu from '../assets/TeamMembers/priyanshu.jpg';
import Aditya from '../assets/TeamMembers/aditya.jpg'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Seoimg from '../assets/seo.jpg';
import GoogleBusiness from '../assets/googlebusiness.webp';
import Search from '../assets/search.avif';
import { useNavigate } from 'react-router-dom';
import Carousel from '../components/Carousel';
const Home = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Initialize GSAP with ScrollTrigger
        gsap.registerPlugin(ScrollTrigger);

        // Create GSAP animations using ScrollTrigger
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".services",
                start: "top 80%",
                scrub: false,
            }
        });
        const t2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-2",
                start: "top 80%",
                scrub: false,
            }
        });

        const t3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-3",
                start: "top 80%",
                scrub: false,
            }
        });
        const t4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-5",
                start: "top 80%",
                scrub: false,
            }
        });
        const t5 = gsap.timeline({
            scrollTrigger: {
                trigger: ".section-6",
                start: "top 80%",
                scrub: false,
            }
        });

        // Define animations
        tl.fromTo('.services', { opacity: 0, y: 0 }, { opacity: 1, y: -30, duration: 1 });
        t2.fromTo('.section-2', { opacity: 0, y: 0 }, { opacity: 1, y: -30, duration: 1 });
        t3.fromTo('.section-3', { opacity: 0, y: 0, scale: 0.8 }, { opacity: 1, y: -30, scale: 1, duration: 0.7 });
        t4.fromTo('.img1', { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, duration: 0.5 });
        t4.fromTo('.img2', { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, duration: 0.5, delay: 0.01 });
        t4.fromTo('.img3', { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, duration: 0.5, delay: 0.01 });
        t4.fromTo('.img4', { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, duration: 0.5, delay: 0.01 });
        t4.fromTo('.img5', { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, duration: 0.5, delay: 0.01 });
        t5.fromTo('.section-6', { opacity: 0, y: 0 }, { opacity: 1, y: -30, duration: 0.7 });

        // Ensure animations restart on component re-mount or resize
        ScrollTrigger.refresh();
    }, []);

    return (
        <>
            <TopBar />

            {/* we offer section  */}
            <section className="flex flex-col services opacity-100">
                <div className="flex flex-col items-center justify-end h-[150px] sm:h-[200px]">
                    <p className="font-light text-lg text-gray-500">We Offer</p>
                    <p className="mt-2 text-4xl sm:text-5xl font-bold text-[#1a1a1d]">Quality Services</p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 px-4 md:px-20 lg:px-[200px] place-items-center">
                    <QualityServices path={"/ecom-management"} title={"Ecommerce Management"} text={"Optimize your online store for increased sales and growth."} image={EcomManagement} />
                    <QualityServices path={"/website-dev"} title={"Website Development"} text={"Professional design and development for stunning websites."} image={WebsiteDev} />
                    <QualityServices path={"/graphic-design"} title={"Graphic Design"} text={"Professional design and development for stunning websites."} image={GraphicDeisgn} />
                    <QualityServices path={"/social-media-marketing"} title={"Social Media Marketing"} text={"Professional design and development for stunning websites."} image={SocialMedia} />
                    <QualityServices path={"/taxation"} title={"Taxation and accounting"} text={"Professional design and development for stunning websites."} image={Taxation} />
                    <QualityServices path={"/seo"} title={"Search Engine Optimization"} text={"Professional design and development for stunning websites."} image={Seo} />
                    <QualityServices path={"/digital-marketing"} title={"Digital Marketing"} text={"Professional design and development for stunning websites."} image={DigitalMarketing} />
                    <QualityServices path={"/advertising"} title={"Advertising"} text={"Professional design and development for stunning websites."} image={Ads} />
                </div>
            </section>

            {/* we offer section  */}

            <section className="mt-20 w-full section-2">
                <div className="flex flex-wrap w-full sm:h-[370px]">
                    <div className="w-full md:w-1/3 h-[300px] sm:h-full relative">
                        <div className="absolute bg-pink-500 w-full  h-full opacity-0 hover:opacity-100 cursor-pointer transition-all duration-300 flex pl-8 pt-5 flex-col">
                            <p className="text-2xl font-semibold text-white relative">
                                <div className="absolute left-[-14px] top-1 bg-white h-6 w-[3px] rounded"></div>
                                boosted my sales</p>
                            <p className="text-white text-sm mt-2">E-commerce</p>
                        </div>
                        <img className="w-full h-full object-cover" src={GoogleBusiness} alt="" />
                    </div>
                    <div className="w-full md:w-1/3 relative h-[300px] sm:h-full ">
                        <div className="absolute bg-yellow-400 w-full  h-full opacity-0 hover:opacity-100 cursor-pointer transition-all duration-300 flex pl-8 pt-5 flex-col">
                            <p className="text-2xl font-semibold text-white relative">
                                <div className="absolute left-[-14px] top-1 bg-white h-6 w-[3px] rounded"></div>
                                Gives quick response</p>
                            <p className="text-white text-sm mt-2">E-commerce</p>
                        </div>
                        <img className="w-full h-full object-cover" src={Search} alt="" />
                    </div>
                    <div className="w-full md:w-1/3 relative h-[300px] sm:h-full ">
                        <div className="absolute bg-orange-500 w-full  h-full opacity-0 hover:opacity-100 cursor-pointer transition-all duration-300 flex pl-8 pt-5 flex-col">
                            <p className="text-2xl font-semibold text-white relative">
                                <div className="absolute left-[-14px] top-1 bg-white h-6 w-[3px] rounded"></div>
                                Seen good growth</p>
                            <p className="text-white text-sm mt-2">E-commerce</p>
                        </div>
                        <img className="w-full h-full object-cover" src={Seoimg} alt="" />
                    </div>
                </div>

                <div className="h-36 w-full bg-gray-200 flex justify-center items-center text-center text-lg px-4">
                    <p><span className="font-bold">Always take </span> <span className="italic"> time to think before you do something. </span> <span className="font-bold"> Check our clients Feedback </span></p>
                </div>
            </section>

            <section className="hidden lg:block mt-36 px-4 md:px-20 lg:px-40 section-3">
                <div className="">
                    <img className="w-full h-auto" src={Strategy} alt="" />
                </div>
            </section>

            <section className="flex lg:hidden flex-col mt-20 items-center justify-center section-4">
                <div className="flex flex-col">

                    <div className="w-[220px] h-[220px]">
                        <img src={Planning} alt="" />
                    </div>
                    <p className="w-full flex justify-center font-semibold top-[-15px] relative">Planning</p>
                </div>

                <div className="flex flex-col mt-10">

                    <div className="w-[220px] h-[220px]">
                        <img src={Strategy2} alt="" />
                    </div>
                    <p className="w-full flex justify-center font-semibold top-[-15px] relative">Strategy</p>
                </div>

                <div className="flex flex-col mt-10">

                    <div className="w-[220px] h-[220px]">
                        <img src={Optimizaiton} alt="" />
                    </div>
                    <p className="w-full flex justify-center font-semibold top-[-15px] relative">Optimization</p>
                </div>

                <div className="flex flex-col mt-10">

                    <div className="w-[220px] h-[220px]">
                        <img src={Research} alt="" />
                    </div>
                    <p className="w-full flex justify-center font-semibold top-[-15px] relative">Research</p>
                </div>

                <div className="flex flex-col mt-10">

                    <div className="w-[220px] h-[220px]">
                        <img src={Results} alt="" />
                    </div>
                    <p className="w-full flex justify-center font-semibold top-[-15px] relative">Results</p>
                </div>

            </section>

            <section className="flex flex-col mt-12 section-5">
                <div className="flex flex-col items-center justify-end h-[150px] sm:h-[200px] mb-20" >
                    <p className="font-light text-lg text-gray-500">Growing your business</p>
                    <p className="mt-4 text-4xl smtext-5xl text-center font-bold text-[#1a1a1d]">The Team <span className="font-light italic">That You Need</span></p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                </div>
                <div className="w-full px-2 md:px-20 lg:px-[200px] flex flex-col">
                    <div className="flex flex-wrap justify-center" >
                        <div className="flex flex-col w-full md:w-1/2 items-center mb-10 md:mb-0 cursor-pointer" onClick={() => navigate('/team-member/ravi-kol')}>
                            <div className="w-[250px] md:w-[350px] overflow-hidden lg:w-[380px] h-[250px] md:h-[350px] lg:h-[450px] img1">
                                <img className="w-full h-[120%] top-[-50px] relative" src={Ravi} alt="" />
                            </div>
                            <p className="font-bold text-xl mb-1 mt-3">Ravi Kol</p>
                            <p className="text-sm">Founder</p>
                        </div>
                        <div className="flex flex-col w-full md:w-1/2 items-center mb-10 md:mb-0 cursor-pointer" onClick={() => navigate('/team-member/vinay-kumar-chadar')}>
                            <div className="w-[250px] md:w-[350px] overflow-hidden lg:w-[380px] h-[250px] md:h-[350px] lg:h-[450px] img1">
                                <img className="w-full h-[120%]" src={Vinay} alt="" />
                            </div>
                            <p className="font-bold text-xl mb-1 mt-3">Vinay Kumar Chadar</p>
                            <p className="text-sm">Co-Founder</p>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center mt-20">
                        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 items-center mb-10 md:mb-0 cursor-pointer" onClick={() => navigate('/team-member/priyanshu-koshta')}>
                            <div className="w-[250px] md:w-[350px] lg:w-[380px] overflow-hidden h-[250px] md:h-[350px] lg:h-[340px] img2">
                                <img className="w-full relative top-[-40px]" src={Priyanshu} alt="" />
                            </div>
                            <p className="font-bold text-xl mb-1 mt-3">Priyanshu Koshta <span className="font-normal text-sm">(4yrs Experience)</span></p>
                            <p className="text-sm">Senior Amazon/Flipkart Manager</p>
                        </div>
                        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 items-center mb-10 md:mb-0 cursor-pointer" onClick={() => navigate('/team-member/shristi-jha')}>
                            <div className="w-[250px] md:w-[350px] overflow-hidden lg:w-[380px] h-[250px] md:h-[350px] lg:h-[340px] img2">
                                <img className="w-full top-[-50px] relative" src={Shrishti} alt="" />
                            </div>
                            <p className="font-bold text-xl mb-1 mt-3">Shristi Jha <span className="font-normal text-sm">(4yrs Experience)</span></p>
                            <p className="text-sm">Senior Amazon Account Manager</p>
                        </div>
                        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 items-center mb-10 md:mb-0 cursor-pointer" onClick={() => navigate('/team-member/anvita-haldkar')}>
                            <div className="w-[250px] md:w-[350px] lg:w-[380px] overflow-hidden h-[250px] md:h-[350px] lg:h-[340px] img2">
                                <img className="w-full top-[-40px] relative " src={Anvita} alt="" />
                            </div>
                            <p className="font-bold text-xl mb-1 mt-3">Anvita Haldkar </p>
                            <p className="text-sm">Amazon Account Manager</p>
                        </div>
                    </div>

                    <div className="flex flex-wrap justify-center mt-20">
                        <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 items-center mb-10 md:mb-0 cursor-pointer" onClick={() => navigate('/team-member/aditya-namdeo')}>
                            <div className="w-[250px] md:w-[350px] lg:w-[380px] h-[250px] md:h-[350px] lg:h-[340px] img3">
                                <img className="w-full h-full object-cover" src={Aditya} alt="" />
                            </div>
                            <p className="font-bold text-xl mb-2  mt-3">Aditya Namdeo <span className="font-normal text-sm">(3yrs Experience)</span></p>
                            <p className="text-sm">Senior Flipkart Account Manager</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="mt-20 px-4 md:px-10 lg:px-10 section-6">
                <div className="flex flex-wrap w-full">
                    <div className="w-full md:w-1/2 flex flex-wrap">
                        <div className="w-full md:w-[calc(100%/3)] bg-orange-600 h-[250px]">
                            <img src={Grow} className="h-full w-full" alt="" />
                        </div>
                        <div className="w-full md:w-custom-width bg-gray-200 h-[250px] flex flex-col text-lg p-5">
                            <div className="flex">
                                helped in <br />
                                boosting sales
                            </div>
                            <p className="text-[14px] text-gray-500 font-light">
                                Excellent Work, Very cooperated understanding. We are very satisfied with the services provided by them. ``BAJAJ & CO.``
                            </p>
                        </div>
                        <div className="w-full md:w-custom-width bg-blue-300 h-[250px]">
                            <img className="h-full w-full" src={Long1} alt="" />
                        </div>
                        <div className="w-full md:w-[calc(100%/3)] bg-gray-200 h-[250px] flex flex-col text-lg p-5">
                            <div className="flex">
                                They are best. Best
                                in Price
                            </div>
                            <p className="text-[14px] text-gray-500 font-light leading-6 mt-2">
                                Excellent Work, Very cooperated understanding. We are very satisfied with the services provided by them. ``BAJAJ & CO.``
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 flex flex-wrap">
                        <div className="w-full md:w-custom-width bg-yellow-500 h-[250px]">
                            <img src={Long2} className="h-full w-full" alt="" />
                        </div>
                        <div className="w-full md:w-[calc(100%/3)] bg-gray-200 h-[250px] flex flex-col text-lg p-5">
                            <div className="flex">
                                Great <br />
                                Communication
                            </div>
                            <p className="text-[14px] text-gray-500 leading-6 mt-2 font-light">
                                Excellent Work, Very cooperated understanding. We are very satisfied with the services provided by them. ``BAJAJ & CO.``
                            </p>
                        </div>
                        <div className="w-full md:w-[calc(100%/3)] bg-gray-200 h-[250px] flex flex-col text-lg p-5">
                            <div className="flex">
                                highly<br />
                                recommended
                            </div>
                            <p className="text-[14px] leading-6 mn-2 text-gray-500 font-light">
                                Excellent Work, Very cooperated understanding. We are very satisfied with the services provided by them. ``BAJAJ & CO.``
                            </p>
                        </div>
                        <div className="w-full md:w-custom-width bg-red-500 h-[250px]">
                            <img src={Long3} className="w-full h-full" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="mt-5 mb-20 flex flex-col">
                <div className="flex flex-col items-center justify-end h-[150px] sm:h-[200px] mb-20">
                    <p className="font-light text-lg text-gray-500">Trust by the Reviews</p>
                    <p className="mt-4 text-4xl smtext-5xl text-center font-bold text-[#1a1a1d]">Our <span className="font-light italic">Testimonials</span></p>
                    <div className="w-10 h-2 bg-gray-800 rounded mt-5"></div>
                </div>
                <Carousel />
            </section>
            <Footer />
        </>
    );
}

export default Home;
